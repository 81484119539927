import * as styles from "../styles/jobb-hos-oss.module.scss";
import ContentMargins from "../components/layout/ContentMargins";
import Divider from "../components/divider/Divider";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";

const JobbHosOss = () => (
    <Layout>
        <Seo title={"Jobb"} />
        <ContentMargins topMargin>
            <PageTitle>Vil du jobbe hos oss?</PageTitle>
            <p>Benedikte Vippeextensions er alltid på utkikk etter nye talenter!</p>
            <p>Det er et stort pluss om du har erfaring fra vippeextensons, vippeløft, hudpleie, negler, skjønnhet og lignende, men ikke et krav. Vi gir deg uansett kursing og internopplæring, slik at du er faglig oppdatert.</p>
            <p>Vi er opptatte av at våre ansatte elsker jobben sin, og er faglig selvsikre! Vi har et eget internt opplærlingprogram med tett oppfølging.</p>
            {/*<span className={styles.bold}>Dette programmet består av:</span>*/}
            {/*<ul>*/}
            {/*    <li>lærlingskvelder</li>*/}
            {/*    <li>dagsøvelser</li>*/}
            {/*    <li>tester i de forskjellige nivåene våre</li>*/}
            {/*    <li>onlinekurs</li>*/}
            {/*    <li>interne kurs i klassisk, volum, 2D, mega-volum og vippeløft</li>*/}
            {/*    <li>konkurranser hvor salongen betaler avgiften</li>*/}
            {/*    <li>workshops</li>*/}
            {/*</ul>*/}
            <p>Vi har et veldig godt miljø i salongen, og vi er ute etter en som liker å jobbe med andre mennesker, og som bidrar til et godt samhold. Jobben som vippetekniker går ut på å produsere bra vipper, men å kunne yte god kundeservice er like viktig. For å få kunden til å booke en time om igjen må man være imøtekommende, svært hyggelig, smilende og ha god kommunikasjon.</p>
            <p>Vi ser etter deg som er: positiv, et JA-menneske, effektiv, snill, vil være den beste i ditt felt og vokser på utfordringer!</p>
            <p>Vi er opptatte av at de ansatte har det fint på jobb, og at de gleder seg til å møte opp! Vi vil at de som jobber hos oss blir lenge, og vi ønsker at man skal føle seg hjemme på jobb, ha et godt forhold til kollegene sine!</p>
            <p>Alle som jobber hos oss har faste stillinger.</p>
            <span className={styles.bold}>Vi fikser det meste av det praktiske:</span>
            <ul>
                <li>Benk, lys og alt annet du skulle trenge på arbeidsplassen</li>
                <li>Alt av utstyr (vippehår, lim, pinsetter, pads osv.)</li>
                <li>Tett oppfølging og tilbakemeldinger</li>
                <li>Vi står for markedsføringen</li>
                <li>Arbeidsgiver tar seg av alt av økonomi</li>
                <li>Godt arbeidsmiljø</li>
                <li>Fine lokaler å tilbringe dagene i</li>
                <li>Mulighet for å vokse faglig og profesjonelt</li>
                <li>Timelønn + provisjon (+ feriepenger, pensjon etc.)</li>
            </ul>
            <Divider color={"white"} />
            <p>Er du interessert? Da vil vi gjerne møte deg!</p>
            <p><a href={"https://goo.gl/forms/GOXlCl5HlbvpkGa43"}>Fyll ut dette skjemaet så tar vi kontakt med deg!</a></p>
            <p>Er du nysgjerrig på hvordan vippene vi legger ser ut? Se det på vår <a href={"https://www.instagram.com/benediktevipper/"}>Instagram</a>-side!</p>
            {/*<Grid container>*/}
            {/*    <Grid item xs={12} md={6}>Cell 1</Grid>*/}
            {/*    <Grid item xs={12} md={6}>Cell 2</Grid>*/}
            {/*</Grid>*/}
        </ContentMargins>
    </Layout>
);

export default JobbHosOss;